<template>
  <div class="container" style="padding-top: 100px; padding-bottom: 100px;">
    <p class="p1"><strong>Politique de confidentialit&eacute;</strong></p>
    <p class="p1"><strong>I. PR&Eacute;SENTATION</strong></p>
    <p class="p2">
      Edays (&quot; <strong>Edays</strong> &quot;, &quot;
      <strong>Nous</strong> &quot;, &quot; <strong>Nous</strong> &quot;)
      respecte votre droit &agrave; la vie priv&eacute;e et comprend
      l&apos;importance de prot&eacute;ger vos donn&eacute;es personnelles
      (&quot; <strong>Donn&eacute;es</strong> &quot;).&nbsp;
    </p>
    <p class="p2">&nbsp;</p>
    <p class="p2">
      Nous avons adopt&eacute; cette politique de confidentialit&eacute; (la
      &laquo;<strong>&nbsp;Politique</strong> &raquo;) afin d&apos;expliquer
      comment nous recueillons, traitons, stockons, partageons et
      prot&eacute;geons les Donn&eacute;es que vous Nous fournissez ou que nous
      recueillons lorsque vous utilisez notre site Web (&laquo;<strong
        >&nbsp;Site Web</strong
      >
      &raquo;), remplissez nos questionnaires (&laquo;
      <strong>Questionnaires</strong> &raquo;), vous inscrire et utiliser notre
      application (&laquo; <strong>Application</strong> &raquo;) et nos services
      bien-&ecirc;tre et motivation (nos &laquo;
      <strong>Services</strong> &raquo;) ou toute autre fonctionnalit&eacute;
      op&eacute;r&eacute;e par Edays.&nbsp;
    </p>
    <p class="p2">&zwj;</p>
    <p class="p2">
      Nous nous r&eacute;servons le droit de modifier les termes de cette
      politique &agrave; tout moment et &agrave; notre seule discr&eacute;tion.
      Si nous modifions la Politique, nous publierons les modifications, selon
      le support, sur la page d&apos;accueil du Site et/ou sur la page
      d&apos;accueil de l&apos;Application afin que vous en soyez pleinement
      inform&eacute;. Toute modification de la Politique prendra effet trente
      (30) jours apr&egrave;s sa publication. Votre utilisation continue de nos
      Services constituera une acceptation des modifications de la politique.
    </p>
    <p class="p1">
      <strong>II. QUEL TYPE ET QUAND NOUS COLLECTONS VOS DONN&Eacute;ES</strong>
    </p>
    <p class="p1"><strong>Sur le site Internet</strong></p>
    <p class="p2">
      Nous collectons les types de Donn&eacute;es suivants lorsque vous visitez
      le Site et, le cas &eacute;ch&eacute;ant, souscrivez &agrave;
      l&apos;Application afin d&apos;acc&eacute;der &agrave; nos Services :
    </p>
    <ul class="ul1">
      <li class="li2">
        <strong>vos donn&eacute;es de connexion</strong> telles que votre
        adresse IP lorsque vous acc&eacute;dez au Site ;
      </li>
      <li class="li2">
        <strong>vos informations d&apos;identification</strong> telles que vos
        nom et pr&eacute;nom lorsque vous vous inscrivez &agrave;
        l&apos;Application afin d&apos;acc&eacute;der &agrave; nos Services ou
        de demander des informations sur Edays;
      </li>
      <li class="li2">
        <strong>vos donn&eacute;es de contact</strong> telles que votre adresse
        e-mail, num&eacute;ro de t&eacute;l&eacute;phone, lorsque vous vous
        inscrivez &agrave; l&apos;Application et website afin
        d&apos;acc&eacute;der &agrave; nos Services et/ou lorsque vous demandez
        l&apos;assistance de Edays;
      </li>
      <li class="li2">
        <strong>vos habitudes de vie</strong> telles que votre pratique de la
        m&eacute;ditation ou votre fr&eacute;quence d&apos;exercice lorsque vous
        remplissez le questionnaire sur le Site ou l&rsquo;application;
      </li>
      <li class="li2">
        <strong>vos donn&eacute;es de transaction</strong> telles que les
        donn&eacute;es bancaires et les informations de facturation lorsque vous
        vous abonnez &agrave; l&apos;Application afin d&apos;acc&eacute;der
        &agrave; nos Services ;
      </li>
      <li class="li2">
        <strong
          >vos donn&eacute;es relatives &agrave; vos fonctionnalit&eacute;s
          techniques</strong
        >
        telles que votre syst&egrave;me d&apos;exploitation, afin de vous aider
        &agrave; utiliser l&apos;Application et les Services ;
      </li>
      <li class="li2">
        <strong
          >le contenu, les communications et les autres informations que vous
          nous fournissez</strong
        >
        lorsque vous utilisez la base de connaissances et la base de services
        d&apos;assistance ;
      </li>
      <li class="li2">
        <strong>votre titre d&apos;emploi</strong> lorsque vous demandez des
        informations pour le service personnalis&eacute;
      </li>
    </ul>
    <p class="p4"><br /></p>
    <p class="p1">
      <strong
        >III. POUR QUELLES FINALIT&Eacute;S ET BASE JURIDIQUE NOUS TRAITONS VOS
        DONN&Eacute;ES</strong
      >
    </p>
    <p class="p1"><strong>Sur le site Internet</strong></p>
    <p class="p2">
      Nous traitons vos Donn&eacute;es lorsque vous visitez le Site afin
      d&apos;optimiser votre navigation et d&apos;am&eacute;liorer votre
      exp&eacute;rience utilisateur sur la base des int&eacute;r&ecirc;ts
      l&eacute;gitimes que Nous poursuivons.<span class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p2">
      Nous traitons enfin vos Donn&eacute;es lorsque vous vous abonnez &agrave;
      notre Application et Services via le Site afin de finaliser votre
      abonnement et vous donner acc&egrave;s &agrave; l&apos;Application et aux
      Services sur la base de l&apos;ex&eacute;cution de votre contrat avec
      Nous.&nbsp;
    </p>
    <p class="p3"><br /></p>
    <p class="p1"><strong>Sur la demande</strong></p>
    <p class="p2">
      Nous traitons vos Donn&eacute;es afin d&apos;ex&eacute;cuter notre contrat
      avec vous et de vous fournir les Services notamment pour les
      finalit&eacute;s suivantes :&nbsp;
    </p>
    <ul class="ul1">
      <li class="li2">
        <strong>Cr&eacute;er et g&eacute;rer votre compte</strong> afin que vous
        puissiez acc&eacute;der et utiliser les Services ;
      </li>
      <li class="li2">
        <strong>G&eacute;rer le support technique et client</strong> afin de
        vous aider &agrave; r&eacute;soudre vos probl&egrave;mes techniques en
        relation par exemple avec la gestion de votre compte ou
        l&apos;utilisation des Services et/ou vos probl&egrave;mes clients en
        relation avec les r&eacute;clamations d&apos;honoraires.
      </li>
    </ul>
    <p class="p2">
      Nous traitons vos Donn&eacute;es afin d&apos;ex&eacute;cuter notre contrat
      avec vous et de vous fournir les Services notamment pour les
      finalit&eacute;s suivantes :&nbsp;
    </p>
    <ul class="ul1">
      <li class="li2">
        <strong
          >Participation aux enqu&ecirc;tes et proc&eacute;dures (y compris les
          proc&eacute;dures judiciaires) men&eacute;es par les autorit&eacute;s
          publiques ou les agences gouvernementales,</strong
        >
        notamment, dans le but de d&eacute;tecter, d&apos;enqu&ecirc;ter et de
        poursuivre les actes ill&eacute;gaux ;
      </li>
      <li class="li2">
        <strong
          >Pr&eacute;vention, d&eacute;tection et att&eacute;nuation des
          activit&eacute;s ill&eacute;gales</strong
        >
        (par exemple, fraude, blanchiment d&apos;argent et financement du
        terrorisme) ;
      </li>
      <li class="li2">
        <strong
          >Se conformer aux demandes d&apos;informations de tiers sur la base
          des droits d&apos;information statutaires qu&apos;ils ont contre
          nous</strong
        >
        (par exemple, en cas d&apos;atteinte &agrave; la propri&eacute;t&eacute;
        intellectuelle, de piratage de produits ou de toute autre
        activit&eacute; ill&eacute;gale) ;
      </li>
      <li class="li2">
        <strong
          >Assurer la s&eacute;curit&eacute; des informations de nos
          Services&nbsp;;</strong
        >
      </li>
      <li class="li2">
        <strong>Conservation et stockage de vos Donn&eacute;es</strong> pour se
        conformer aux exigences l&eacute;gales sp&eacute;cifiques en
        mati&egrave;re de conservation.
      </li>
    </ul>
    <p class="p2">
      Nous traitons vos Donn&eacute;es lorsque cela est n&eacute;cessaire aux
      fins des int&eacute;r&ecirc;ts l&eacute;gitimes que Nous poursuivons,
      notamment les finalit&eacute;s suivantes :&nbsp;
    </p>
    <ul class="ul1">
      <li class="li2">
        Am&eacute;liorer l&apos;Application et les Services en surveillant leur
        utilisation, afin d&apos;am&eacute;liorer la satisfaction des
        Utilisateurs&nbsp;;
      </li>
      <li class="li2">
        Personnaliser et am&eacute;liorer votre exp&eacute;rience utilisateur en
        vous contactant pour d&eacute;terminer comment nous pouvons
        am&eacute;liorer nos Services afin de mieux r&eacute;pondre &agrave; vos
        besoins&nbsp;;
      </li>
      <li class="li2">
        Vous envoyer des communications promotionnelles sur nos Services,
        notamment nos nouvelles offres par voie &eacute;lectronique ou
        SMS&nbsp;;
      </li>
      <li class="li2">
        Fournir des annonces de produits ou des informations sur des sujets de
        bien-&ecirc;tre ou d&apos;autres informations que nous pensons que vous
        trouverez les plus pertinentes et utiles concernant l&apos;utilisation
        des Services&nbsp;;
      </li>
      <li class="li2">
        G&eacute;n&eacute;rer des statistiques globales concernant
        l&apos;utilisation de nos Services.
      </li>
    </ul>
    <p class="p2">
      Nous traitons vos Donn&eacute;es avec votre consentement aux fins
      suivantes :&nbsp;
    </p>
    <ul class="ul1">
      <li class="li2">
        Personnaliser, mesurer et am&eacute;liorer nos publicit&eacute;s ;&nbsp;
      </li>
      <li class="li2">
        Personnaliser et am&eacute;liorer votre exp&eacute;rience utilisateur en
        vous contactant pour d&eacute;terminer comment nous pouvons
        am&eacute;liorer nos Services afin de mieux r&eacute;pondre &agrave; vos
        besoins&nbsp;;
      </li>
      <li class="li2">
        Placer des cookies sur votre navigateur afin de vous envoyer des
        publicit&eacute;s personnalis&eacute;es ;
      </li>
      <li class="li2">
        Vous envoyer des communications promotionnelles sur nos autres Produits
        et Services ou sur les Produits et Services de nos partenaires
        susceptibles de vous int&eacute;resser.
      </li>
    </ul>
    <p class="p2">
      Nous pouvons &eacute;galement anonymiser et/ou agr&eacute;ger et/ou
      anonymiser vos donn&eacute;es &agrave; diverses fins commerciales,
      notamment le d&eacute;veloppement et l&apos;am&eacute;lioration de
      produits, de services et de programmes. Les donn&eacute;es
      anonymis&eacute;es, sous forme individuelle ou agr&eacute;g&eacute;e,
      peuvent &eacute;galement &ecirc;tre utilis&eacute;es &agrave; des fins de
      recherche &agrave; la fois en interne par Edays ou avec des partenaires de
      recherche et d&apos;autres tiers.
    </p>
    <p class="p1">
      <strong>IV. COOKIES ET TECHNOLOGIES SIMILAIRES&nbsp;</strong>
    </p>
    <p class="p2">
      Lorsque vous utilisez les Services, Nous pouvons utiliser des cookies et
      d&apos;autres fonctions informatiques pour collecter vos Donn&eacute;es.
      Les cookies sont de petits fichiers texte cr&eacute;&eacute;s
      automatiquement par votre navigateur et stock&eacute;s sur votre appareil
      lorsque vous vous connectez au Site Web ou &agrave; l&apos;Application et
      utilisez les Services.<span class="Apple-converted-space">&nbsp;</span>
    </p>
    <p class="p3"><br /></p>
    <p class="p2">
      Nous utilisons des cookies qui ne seront pas enregistr&eacute;s sur votre
      appareil ou qui ne seront enregistr&eacute;s sur votre appareil que tant
      que votre navigateur est actif (par exemple, les cookies de session) ou
      qui seront enregistr&eacute;s sur votre appareil pendant une
      p&eacute;riode plus longue (par exemple, les cookies persistants) aux fins
      suivantes : (I) la fourniture des Services, (II) votre support technique
      et l&apos;am&eacute;lioration des Services, (III)
      l&apos;am&eacute;lioration de votre exp&eacute;rience utilisateur et (IV)
      pour vous montrer des publicit&eacute;s personnalis&eacute;es avec votre
      consentement.
    </p>
    <p class="p3"><br /></p>
    <p class="p2">
      Vous avez le choix de refuser de conserver tout ou partie des cookies dans
      votre navigateur ou de d&eacute;sactiver tout ou certains cookies,
      apr&egrave;s avoir accept&eacute; leur d&eacute;p&ocirc;t sur votre
      navigateur, en configurant vos param&egrave;tres. En ce qui concerne
      l&apos;utilisation de technologies li&eacute;es &agrave; la
      publicit&eacute; ou &agrave; la personnalisation, vous pouvez
      d&eacute;terminer si vous consentez ou non &agrave; l&apos;utilisation de
      cookies &agrave; des fins de marketing.
    </p>
    <p class="p1">
      <strong>V. COMMENT NOUS CONSERVONS VOS DONN&Eacute;ES</strong>
    </p>
    <p class="p2">
      Nous (par l&apos;interm&eacute;diaire de nos prestataires) conservons vos
      Donn&eacute;es sur des lieux de stockage situ&eacute;s pour les
      utilisateurs europ&eacute;ens sur le territoire de l&apos;Union
      Europ&eacute;enne pendant une dur&eacute;e n&apos;exc&eacute;dant pas ce
      qui est n&eacute;cessaire aux finalit&eacute;s pour lesquelles vos
      Donn&eacute;es ont &eacute;t&eacute; collect&eacute;es et trait&eacute;es
      par Nous et, en tout &eacute;tat de cause, conform&eacute;ment aux
      exigences l&eacute;gales.<span class="Apple-converted-space">&nbsp;</span>
    </p>
    <p class="p2">&nbsp;</p>
    <p class="p2">
      Vos Donn&eacute;es seront conserv&eacute;es dans notre base active pendant
      le temps n&eacute;cessaire &agrave; la fourniture des Services et seront
      irr&eacute;versiblement supprim&eacute;es ou anonymis&eacute;es par Nous
      ou archiv&eacute;es dans une base de donn&eacute;es interm&eacute;diaire
      avec un acc&egrave;s limit&eacute; &agrave; celle-ci &agrave; la
      r&eacute;siliation de votre abonnement &agrave; l&apos;Application
      &agrave; l&apos;exception de votre contact donn&eacute;es qui seront
      conserv&eacute;es dans notre base active pendant une dur&eacute;e de 3 ans
      afin de vous envoyer des communications promotionnelles sur nos Services
      apr&egrave;s votre consentement.&nbsp;
    </p>
    <p class="p2">&zwj;</p>
    <p class="p2">
      Vos Donn&eacute;es seront archiv&eacute;es dans une base de donn&eacute;es
      interm&eacute;diaire &agrave; acc&egrave;s limit&eacute; lorsque Nous
      sommes l&eacute;galement tenus de conserver vos Donn&eacute;es plus
      longtemps, par exemple pour la conformit&eacute; l&eacute;gale,
      l&apos;&eacute;tablissement de la preuve de nos droits ou de notre contrat
      avec vous, ou &agrave; des fins fiscales, comptables ou d&apos;audit. Dans
      ces cas, la dur&eacute;e du traitement de stockage d&eacute;pendra des
      d&eacute;lais de prescription ou de conservation l&eacute;gaux respectifs
      et sera supprim&eacute;e apr&egrave;s l&apos;expiration des d&eacute;lais
      de conservation correspondants.&nbsp;
    </p>
    <p class="p1">
      <strong
        >VI. TRANSFERT DE VOS DONN&Eacute;ES HORS DE L&apos;UNION
        EUROP&Eacute;ENNE</strong
      >
    </p>
    <p class="p2">
      Certains de nos fournisseurs de services ainsi que certains de nos
      employ&eacute;s sont situ&eacute;s en dehors de l&apos;Union
      europ&eacute;enne ou ont des bureaux dans des pays o&ugrave; les lois sur
      la protection des donn&eacute;es peuvent offrir un niveau de protection
      diff&eacute;rent de celui des lois de votre pays. Lors du transfert des
      Donn&eacute;es des utilisateurs europ&eacute;ens &agrave; nos prestataires
      de services, nous nous assurons qu&apos;un m&eacute;canisme de transfert
      ad&eacute;quat est en place.<span class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p1">
      <strong
        >VII. COMMENT NOUS GARDONS VOS DONN&Eacute;ES
        S&Eacute;CURIS&Eacute;ES</strong
      >
    </p>
    <p class="p2">
      Nous cherchons &agrave; prot&eacute;ger la s&eacute;curit&eacute; de vos
      donn&eacute;es et avons mis en place des mesures de s&eacute;curit&eacute;
      conformes aux pratiques accept&eacute;es dans l&apos;industrie pour
      prot&eacute;ger vos donn&eacute;es et en limiter
      l&apos;acc&egrave;s.&nbsp;
    </p>
    <p class="p2">&zwj;</p>
    <p class="p2">
      En particulier, nous avons mis en place des mesures techniques et
      organisationnelles appropri&eacute;es pour minimiser les risques
      associ&eacute;s &agrave; la perte de donn&eacute;es, &agrave;
      l&apos;utilisation abusive, &agrave; l&apos;acc&egrave;s non
      autoris&eacute; et &agrave; la divulgation non autoris&eacute;e &agrave;
      l&apos;aide de la technologie de cryptage, telle que Secure Sockets Layer
      (SSL). Cependant, malgr&eacute; nos efforts pour prot&eacute;ger vos
      Donn&eacute;es, il existe toujours un risque qu&apos;un tiers non
      autoris&eacute; puisse contourner nos syst&egrave;mes de
      s&eacute;curit&eacute; ou que les transmissions de vos Donn&eacute;es sur
      Internet &ecirc;tre intercept&eacute;. Malheureusement, nous ne pouvons
      pas garantir la s&eacute;curit&eacute; absolue de vos donn&eacute;es, ni
      garantir que les donn&eacute;es que vous fournissez ne seront pas
      intercept&eacute;es lors de leur transmission sur Internet.
    </p>
    <p class="p3"><br /></p>
    <p class="p1">
      <strong>VIII. VOS DROITS EN TANT QUE PERSONNE CONCERN&Eacute;E</strong>
    </p>
    <p class="p2">
      Vous disposez des droits suivants sur vos Donn&eacute;es et le traitement
      des donn&eacute;es :
    </p>
    <ul class="ul1">
      <li class="li2">
        droit d&apos;acc&egrave;s &agrave; vos Donn&eacute;es ;
      </li>
      <li class="li2">droit de rectifier vos Donn&eacute;es inexactes ;</li>
      <li class="li2">
        droit d&apos;effacer vos Donn&eacute;es sauf si le traitement est
        n&eacute;cessaire &agrave; l&apos;exercice du droit &agrave; la
        libert&eacute; d&apos;expression et d&apos;information, au respect
        d&apos;une obligation l&eacute;gale, pour des raisons
        d&apos;int&eacute;r&ecirc;t public ou pour la constatation,
        l&apos;exercice ou la d&eacute;fense d&apos;un droit en justice&nbsp;;
      </li>
      <li class="li2">
        droit de demander la limitation du traitement de vos Donn&eacute;es dans
        la mesure o&ugrave; vous contestez l&apos;exactitude de vos
        Donn&eacute;es, le traitement est illicite, mais vous vous opposez
        &agrave; l&apos;effacement des donn&eacute;es personnelles et demandez
        la limitation &agrave; la place, Nous n&apos;avons plus besoin du
        donn&eacute;es, mais vous en avez besoin pour faire valoir, exercer ou
        d&eacute;fendre des droits l&eacute;gaux ou vous vous &ecirc;tes
        oppos&eacute; au traitement&nbsp;;
      </li>
      <li class="li2">
        droit de recevoir une copie de vos Donn&eacute;es dans un format
        &eacute;lectronique structur&eacute; ou d&apos;en demander le transfert
        &agrave; un autre responsable du traitement, lorsque cela est
        techniquement possible&nbsp;;
      </li>
      <li class="li2">
        droit de vous opposer au traitement de vos Donn&eacute;es pour des
        motifs li&eacute;s &agrave; votre situation particuli&egrave;re, y
        compris votre profil ou &agrave; des fins de marketing et de retirer
        votre consentement &agrave; tout moment lorsque le traitement est
        fond&eacute; sur le consentement.
      </li>
    </ul>
    <p class="p2">
      Vous pouvez &eacute;galement introduire une r&eacute;clamation
      aupr&egrave;s d&apos;une autorit&eacute; de contr&ocirc;le en envoyant un
      courrier &agrave; l&apos;autorit&eacute; comp&eacute;tente. En France,
      cette autorit&eacute; est la CNIL accessible &agrave; l&apos;adresse
      suivante : CNIL - 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07.
      <span class="Apple-converted-space">&nbsp;</span>
    </p>
    <p class="p2">
      &zwj; Vous pouvez exercer vos droits en nous adressant une demande claire
      et pr&eacute;cise par email &agrave; <span class="s2">NOTRE EMAIL</span>.
      Votre demande sera trait&eacute;e par notre &eacute;quipe dans un
      d&eacute;lai raisonnable &agrave; compter de sa date de r&eacute;ception
      et accord&eacute;e ou refus&eacute;e sous r&eacute;serve de nos
      obligations l&eacute;gales et dans la limite de vos droits.<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p2">
      &zwj;Vous pouvez &eacute;galement acc&eacute;der et mettre &agrave; jour
      vos Donn&eacute;es &agrave; tout moment en vous connectant &agrave;
      l&apos;Application et en acc&eacute;dant aux param&egrave;tres de votre
      compte.
    </p>
    <p class="p3"><br /></p>
    <p class="p1">
      <strong>IX. COMMENT NOUS G&Eacute;RONS LA PROTECTION DES ENFANTS</strong>
    </p>
    <p class="p2">
      Nous nous engageons &agrave; prot&eacute;ger la vie priv&eacute;e des
      enfants. Notre site Web, notre application et nos services ne sont pas
      con&ccedil;us ou destin&eacute;s aux enfants et nous ne collectons pas
      sciemment les donn&eacute;es des utilisateurs de moins de 18 ans.<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p2">
      &zwj;Dans tous les cas, Nous demandons &agrave; tous les utilisateurs de
      confirmer lors de la souscription &agrave; l&apos;Application et aux
      Services qu&apos;ils ont 18 ans ou plus et dans le cas contraire, Nous
      demandons le consentement des parents avant d&apos;autoriser
      l&apos;utilisateur &agrave; acc&eacute;der &agrave; l&apos;Application et
      aux Services et ne collectez jamais les donn&eacute;es d&apos;enfants de
      moins de 16 ans.<span class="Apple-converted-space">&nbsp;</span>
    </p>
    <p class="p2">
      &zwj;Pour les utilisateurs de moins de 18 ans, nous pouvons limiter la
      mani&egrave;re dont nous collectons, utilisons et stockons leurs
      donn&eacute;es, ce qui signifie que nous ne pourrons pas autoriser
      l&apos;acc&egrave;s &agrave; certains d&eacute;fis &agrave; ces
      utilisateurs.&nbsp;
    </p>
    <p class="p1">
      <strong>X. AVEC QUI NOUS PARTAGEONS VOS DONN&Eacute;ES</strong>
    </p>
    <p class="p2">
      Nous partageons vos Donn&eacute;es avec les collaborateurs de nos services
      internes tels que les services informatiques, d&eacute;veloppement ou
      marketing afin de vous fournir les Services et vous accompagner dans votre
      exp&eacute;rience utilisateur.&nbsp;
    </p>
    <p class="p2">
      &zwj;Nous partageons &eacute;galement vos Donn&eacute;es avec des tiers, y
      compris des prestataires de services agissant en notre nom, qui peuvent
      h&eacute;berger vos donn&eacute;es et/ou g&eacute;rer le paiement de
      l&apos;abonnement pour Nous et/ou vous envoyer des communications
      promotionnelles sur nos Services et d&apos;autres produits et/ou
      d&eacute;tecter, pr&eacute;venir ou att&eacute;nuer les activit&eacute;s
      ill&eacute;gales et/ou &eacute;laborer des statistiques
      agr&eacute;g&eacute;es.
    </p>
    <p class="p2">
      Dans ce cas, nous nous assurons que les fournisseurs de services
      adh&egrave;rent &agrave; notre politique de confidentialit&eacute; et se
      conforment aux m&ecirc;mes obligations de protection des donn&eacute;es
      que celles qui nous sont impos&eacute;es. Nous veillons &eacute;galement
      &agrave; ce qu&apos;ils pr&eacute;sentent des garanties suffisantes pour
      mettre en &oelig;uvre des mesures techniques et organisationnelles
      appropri&eacute;es de mani&egrave;re &agrave; ce que le traitement
      r&eacute;ponde aux exigences de la pr&eacute;sente Politique et plus
      largement de la r&eacute;glementation applicable en mati&egrave;re de
      protection des donn&eacute;es et de confidentialit&eacute;.&nbsp;
    </p>
    <p class="p2">&zwj;</p>
    <p class="p2">
      Nous pouvons partager vos donn&eacute;es avec certains de nos partenaires
      avec votre consentement afin de vous envoyer des offres sur leurs produits
      et services qui, selon nous, pourraient avoir un int&eacute;r&ecirc;t pour
      vous.<span class="Apple-converted-space">&nbsp;</span>
    </p>
    <p class="p2">&nbsp;</p>
    <p class="p2">
      Nous pouvons enfin &ecirc;tre amen&eacute;s &agrave; communiquer vos
      Donn&eacute;es &agrave; d&apos;autres autorit&eacute;s comp&eacute;tentes
      pour exercer nos droits conf&eacute;r&eacute;s par la loi ou pour nous
      conformer &agrave; la loi ou &agrave; une d&eacute;cision de justice. Sauf
      si la loi ou la juridiction l&apos;exige, nous ne sommes pas tenus de vous
      informer de ces divulgations de donn&eacute;es.
    </p>
    <p class="p1"><strong>XI. LIENS DE SITES WEB DE TIERS</strong></p>
    <p class="p2">
      Notre site Web et notre application peuvent contenir des liens ou des
      contenus tiers. Par le biais de ces liens ou contenus, vous pouvez fournir
      vos Donn&eacute;es &agrave; des tiers pour lesquels Nous ne sommes pas
      responsables de la mani&egrave;re dont ils collectent ou utilisent vos
      Donn&eacute;es et nous ne garantissons pas le respect des m&ecirc;mes
      pratiques de protection des donn&eacute;es que celles de Edays. Nous vous
      encourageons &agrave; lire les politiques de confidentialit&eacute; de
      chaque site Web, application et/ou service tiers que vous visitez ou
      utilisez, y compris les tiers avec lesquels vous interagissez via nos
      services.
    </p>
    <p class="p3"><br /></p>
    <p class="p2">
      En outre, nous pouvons compter sur des annonceurs, des r&eacute;seaux
      publicitaires et des serveurs publicitaires tiers pour promouvoir notre
      application. Cela inclut Facebook, Instagram, Linkdin, Youtube, Twitter ou
      Google. Ces tiers peuvent utiliser des cookies seuls ou en conjonction
      avec des balises Web ou d&apos;autres technologies de suivi pour collecter
      des informations sur nos utilisateurs. Cela peut inclure des informations
      sur le comportement des utilisateurs sur cette application et sur
      d&apos;autres applications pour leur proposer des publicit&eacute;s
      (comportementales) bas&eacute;es sur les int&eacute;r&ecirc;ts. Aucune
      information que vous partagez dans notre application ne sera
      partag&eacute;e avec des annonceurs tiers. Nous ne contr&ocirc;lons pas
      les technologies de suivi de ces tiers ni la mani&egrave;re dont elles
      peuvent &ecirc;tre utilis&eacute;es. Si vous avez des questions sur une
      annonce, vous devez contacter directement l&apos;annonceur
      responsable.&nbsp;
    </p>
    <p class="p2">En vigueur : 08 Mai 2022</p>
    <p class="p2">
      Edays, Oasis SAS est situ&eacute;e au 6 Chemin du
      D&eacute;barcadr&egrave;re, 06590 Th&eacute;oule Sur Mer, France
    </p>
  </div>
</template>
<script>
export default {};
</script>

<style type="text/css">
p.p1 {
  margin: 0px 0px 2px 0px;
  font: 16px Helvetica;
  color: #000000;
}

p.p2 {
  margin: 0px 0px 0px 0px;
  font: 13px Helvetica;
  color: #000000;
}

p.p3 {
  margin: 0px 0px 0px 0px;
  font: 13px Helvetica;
  color: #000000;
  min-height: 16px;
}

p.p4 {
  margin: 0px 0px 2px 0px;
  font: 16px Helvetica;
  color: #000000;
  min-height: 19px;
}

p.p5 {
  margin: 0px 0px 2px 0px;
  font: 16px Helvetica;
  color: #fb0007;
  min-height: 19px;
}

li.li2 {
  margin: 0px 0px 0px 0px;
  font: 13px Helvetica;
  color: #000000;
}

span.s1 {
  text-decoration: underline;
  color: #d2910f;
}

span.s2 {
  color: #fb0007;
}

ul.ul1 {
  list-style-type: disc;
}
</style>
